import React from 'react';
import { Container, Row, Col, Button, Card, CardBody, CardTitle, CardText, CardImg } from 'reactstrap';
import { useNavigate, Link } from 'react-router-dom';
import headimg from "../assets/images/hero-img.png"
import headmain from "../assets/images/bookimg.png"
import { homeimg, managementIcon } from "../theme/Images"
import { registerIcon, rgwriter, rgproducer, rgmentor, notiIcon, contractIcon, mentorIcon, keyImg } from "../theme/Images"
import { FaCheckCircle } from "react-icons/fa";
import { SiLibreofficewriter, SiCodementor } from "react-icons/si";
import { BiCameraMovie } from "react-icons/bi";
import { IoMdArrowDroprightCircle } from "react-icons/io";

const Home = () => {
  const navigate = useNavigate();
  const handleRegisterClick = () => {
    // Replace '/register' with your desired route
    navigate('/register');
  };
  return (
    <div>
      <div className="homepage">
        <Container className="py-5">
          <h1 className="displaymain  py-3">WRITER’S HEAVEN</h1>

          <Row className=" align-items-center g-0">
            <Col md="7">
              <div className="">
                {/* <h1 className="displaymain  py-5">WRITER’S HEAVEN</h1> */}

                <p className="header-p text-center "><span style={{ fontWeight: "bold" }}>U</span>nleash your creativity and connect with storytellers,  mentors, and producers in one vibrant platform.</p>
                <div className="mt-5">
                  <Button color="primary" className="me-3">Get Started</Button>
                  <Button color="secondary" className="me-3">contact us</Button>
                </div>
              </div>
            </Col>

            {/* Right Image Section */}
            <Col md="5">
              <img
                src={homeimg}
                alt="Business Solutions"
                className="img-fluid"
              />
            </Col>
          </Row>

        </Container>
      </div>

      {/* Featured Stories */}
      <section className=" reg-section py-5">
        <Container>
          <h2 className="text-center register-head2">Become a Part of Our Creative Network</h2>
          <p className="text-center mb-4 register-p">Sign up for free and connect with writers, producers, and mentors to bring your creative visions to life</p>
          <Row>
            <Col md="4">
              <Card className='writer-cardbody '>
                <img src={rgwriter} alt="Business Solutions" className="img-fluid  img-reg" />
                <CardBody className='text-center writer-card'>
                  <CardTitle tag="h5" className='reg-card-h'>Writer</CardTitle>
                  <CardText className=''>
                    <ul className="custom-list ">
                      <li className=''>
                        <FaCheckCircle className='m-1 check-reg' />
                        <h3 className="text-content">
                          Express your creativity
                        </h3>
                      </li>
                      <li className=''>
                        <FaCheckCircle className='m-1 check-reg' />
                        <h3 className="text-content">
                          Genres
                        </h3>
                      </li>
                      <li className=''>
                        <FaCheckCircle className='m-1 check-reg' />
                        <h3 className="text-content">
                          Mentorship opportunities
                        </h3>
                      </li>
                      <li className=''>
                        <FaCheckCircle className='m-1 check-reg' />
                        <h3 className="text-content">
                          Get noticed
                        </h3>
                      </li>
                    </ul>
                  </CardText>
                  <Button className='register-btn p-2' onClick={handleRegisterClick}>Register as Writer <IoMdArrowDroprightCircle className='arrow' /></Button>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className='writer-cardbody '>
                <img src={rgproducer} alt="Business Solutions" className="img-fluid  img-reg" />
                <CardBody className='text-center writer-card'>
                  <CardTitle tag="h5" className='reg-card-h'>Producer</CardTitle>
                  <CardText className=''>
                    <ul className="custom-list ">
                      <li className=''>
                        <FaCheckCircle className='m-1 check-reg' />
                        <h3 className="text-content">
                          Discover original stories
                        </h3>
                      </li>
                      <li className=''>
                        <FaCheckCircle className='m-1 check-reg' />
                        <h3 className="text-content">
                          Rate & Give feedback 
                        </h3>
                      </li>
                      <li className=''>
                        <FaCheckCircle className='m-1 check-reg' />
                        <h3 className="text-content">
                          Contract & Secure rights
                        </h3>
                      </li>
                      <li className=''>
                        <FaCheckCircle className='m-1 check-reg' />
                        <h3 className="text-content">
                          Search tailored stories
                        </h3>
                      </li>
                    </ul>
                  </CardText>
                  <Button className='register-btn p-2'>Register as Producer <IoMdArrowDroprightCircle className='arrow' /></Button>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className='writer-cardbody '>
                <img src={rgmentor} alt="Business Solutions" className="img-fluid  img-reg" />
                <CardBody className='text-center writer-card'>
                  <CardTitle tag="h5" className='reg-card-h'>Mentor</CardTitle>
                  <CardText className=''>
                    <ul className="custom-list ">
                      <li className=''>
                        <FaCheckCircle className='m-1 check-reg' />
                        <h3 className="text-content">
                          Guide emerging talent
                        </h3>
                      </li>
                      <li className=''>
                        <FaCheckCircle className='m-1 check-reg' />
                        <h3 className="text-content">
                          Genres of your expertise
                        </h3>
                      </li>
                      <li className=''>
                        <FaCheckCircle className='m-1 check-reg' />
                        <h3 className="text-content">
                          Earn for your guidance
                        </h3>
                      </li>
                      <li className=''>
                        <FaCheckCircle className='m-1 check-reg' />
                        <h3 className="text-content">
                          Impact future stories
                        </h3>
                      </li>
                    </ul>
                  </CardText>
                  <Button className='register-btn p-2'>Register as Mentor <IoMdArrowDroprightCircle className='arrow' /></Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* More Information Section */}
      <section className="key-feature py-5 ">
        <Container>
          {/* <h2 className="text-center mb-4">More Information</h2> */}
          <Row>
            <Col md="4">
              <img
                src={keyImg}
                alt="Business Solutions"
                className="img-fluid"
              />
            </Col>
            <Col md="8">
              <Row className="g-4" xs="1" md="2">
                <Col>
                  <Card className='h-100 key-card'>
                    <CardBody>
                      <CardImg src={managementIcon} alt="Card image" className='img-fluid icon-img ' />
                      <CardTitle tag="h5" className='key-card-h'>Story Submission & Management</CardTitle>
                      <CardText className='key-card-p'>Easily submit and manage your
                        stories, receive feedback, and
                        track interest from producers.</CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className='h-100 key-card' >

                    <CardBody>
                      <CardImg src={mentorIcon} alt="Card image" className='img-fluid icon-img ' />
                      <CardTitle tag="h5" className='key-card-h'>Mentorship Connections</CardTitle>
                      <CardText className='key-card-p'>Find or offer mentorship, get guidance, and develop your skills alongside experienced industry professionals.</CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className='h-100 key-card'>

                    <CardBody>
                      <CardImg src={contractIcon} alt="Card image" className='img-fluid icon-img ' />
                      <CardTitle tag="h5" className='key-card-h'>Contract & Payment Integration</CardTitle>
                      <CardText className='key-card-p'>Securely handle contracts and payments through our platform for a seamless professional experience.</CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className='h-100 key-card'>

                    <CardBody>
                      <CardImg src={notiIcon} alt="Card image" className='img-fluid icon-img ' />
                      <CardTitle tag="h5" className='key-card-h'>Real-Time Notifications</CardTitle>
                      <CardText className='key-card-p'>Stay updated with interactions, feedback, and new opportunities as they happen.</CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

      </section>
      <section className=" reg-section py-5">
        <Container>
          <h2 className="text-center mb-4 more-home">More Information</h2>
          <Row >
            <Col md="6" className=''>
              <Card className='h-100'>
                <CardBody>
                  <CardTitle tag="h4" className='key-card-h'>What is the Story Portal?</CardTitle>
                  <CardText className='key-card-p'> The Story Portal is a dynamic platform that connects
                    writers, producers, and mentors, providing a collaborative
                    space to create, share, and develop compelling stories for
                    screens and beyond.</CardText>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" >
            <Card className='h-100'>
                <CardBody>
                  <CardTitle tag="h4" className='key-card-h'>How Can You Contribute?</CardTitle>
                  <CardText className='key-card-p'>  Join as a writer, producer, or mentor to bring your unique
                talents to the community whether by submitting stories, supporting emerging storytellers, or discovering fresh
                narratives ready for production.</CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

      </section>
    </div>
  );
};

export default Home;
