// import axios from 'axios';

// export const login = async (email, password) => { return await axios.post('/api/login', { email, password }); };
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://kalamkaar.springuplabs.in";

export const registerUser = async (userData) => {
  console.log("API Base URL:", API_BASE_URL); // Debugging line
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/auth/registerWriter`, userData);
    return response.data;
  } catch (error) {
    console.error("Registration API Error:", error.response?.data || error.message);
    throw error.response?.data || error.message;
  }
};


