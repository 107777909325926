
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Button } from 'reactstrap';
const RequestMentorship = ( ) => {
    const { id } = useParams();
    const [mentors, setMentors] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('Request Connect');
    useEffect(() => {
        // Fetch mentors based on genre
        fetchMentorsByGenre(id);
    }, [id]);

    const fetchMentorsByGenre = (storyId) => {
        // Replace with API call to fetch mentors
        const dummyMentors = [
            { id: 1, name: "John Doe", expertise: "Fiction", experience: "5 years", rating: 4.8, status: "Requested" },
            { id: 2, name: "Jane Smith", expertise: "Non-Fiction", experience: "3 years", rating: 4.5, status: "Request Connect" },
            { id: 3, name: "Alice Johnson", expertise: "Science Fiction", experience: "7 years", rating: 4.9, status: "Connected" },
            { id: 4, name: "Mark Wilson", expertise: "Fantasy", experience: "6 years", rating: 4.7, status: "Requested" },
            { id: 5, name: "Chandradeep Bhoite", expertise: "Drama", experience: "10 years", rating: 4, status: "Request Connect" },
            { id: 6, name: "John Doe", expertise: "Fiction", experience: "5 years", rating: 4.8, status: "Requested" },
            { id: 7, name: "Alice Smith", expertise: "Non-Fiction", experience: "3 years", rating: 4.5, status: "Request Connect" },
            { id: 8, name: "Alice Johnson", expertise: "Science Fiction", experience: "7 years", rating: 4.9, status: "Connected" },
            { id: 9, name: "Mark Wilson", expertise: "Fantasy", experience: "6 years", rating: 4.7, status: "Requested" },
            { id: 10, name: "Nilesh Mane", expertise: "Drama", experience: "10 years", rating: 4, status: "Request Connect" },
        ];
        setMentors(dummyMentors);
    };
    const handleConnect = (mentorId) => {
        console.log(`Connecting with mentor ${mentorId}`);
       
    };
    const filterMentorsByStatus = (status) => {
        return mentors.filter(mentor => mentor.status === status);
    };

    return (
        <Container className="my-4">
            <h2 className="text-center mb-4">Find a Mentor for Story {id}</h2>
            <Row>
               
                <Col md="3" className="mb-4 pr-4" style={{ borderRight: '2px solid #ddd' }}>
                    <Button
                     style={{
                        backgroundColor: selectedStatus === 'Request Connect' ? '#6A5DB4' : '#E9F3FD',
                        color: selectedStatus === 'Request Connect' ? '#fff' : '#000',
                        border: 'none',
                        marginBottom: '10px'
                      }}
                        onClick={() => setSelectedStatus('Request Connect')}
                        block
                    >
                        Request Connect
                    </Button>
                    <Button
                     style={{
                        backgroundColor: selectedStatus === 'Requested' ? '#6A5DB4' : '#E9F3FD',
                        color: selectedStatus === 'Requested' ? '#fff' : '#000',
                        border: 'none',
                        marginBottom: '10px'
                      }}
                        onClick={() => setSelectedStatus('Requested')}
                        block
                        className="mt-2"
                    >
                        Requested
                    </Button>
                    <Button
                         style={{
                            backgroundColor: selectedStatus === 'Connected' ? '#6A5DB4' : '#E9F3FD',
                            color: selectedStatus === 'Connected' ? '#fff' : '#000',
                            border: 'none',
                            marginBottom: '10px'
                          }}
                        onClick={() => setSelectedStatus('Connected')}
                        block
                        className="mt-2"
                    >
                        Connected
                    </Button>
                </Col>

     
                <Col md="9">
                    <Row>
                        {filterMentorsByStatus(selectedStatus).map((mentor) => (
                            <Col md="6" lg="4" key={mentor.id} className="mb-4">
                                <Card className="mentor-card h-100  rounded border-0">
                                    <CardBody className="d-flex flex-column align-items-center text-center p-4">
                                        {/* Profile Picture or Initials */}
                                        {mentor.profilePic ? (
                                            <img
                                                src={mentor.profilePic}
                                                alt={mentor.name}
                                                className="mentor-profile-pic mb-3"
                                            />
                                        ) : (
                                            <div className="mentor-profile-initials mb-3">
                                                {mentor.name.split(" ").map(name => name[0]).join("")}
                                            </div>
                                        )}
                                 
                                        <CardTitle tag="h5" className="mentor-name font-weight-bold text-dark">{mentor.name}</CardTitle>
                                        <CardText className="mentor-expertise"><strong>Expertise:</strong> {mentor.expertise}</CardText>
                                        <CardText className="mentor-experience"><strong>Experience:</strong> {mentor.experience}</CardText>
                                        <CardText className="mentor-rating"><strong>Rating:</strong> {mentor.rating} / 5</CardText>
                                    
                                        {mentor.status === 'Request Connect' && (
                                            <Button color="primary" onClick={() => handleConnect(mentor.id)} className="mt-2 mentor-connect-btn">
                                                Request Connect
                                            </Button>
                                        )}
                                        {mentor.status === 'Requested' && (
                                            <Button color="secondary" className="mt-2" disabled>
                                                Requested
                                            </Button>
                                        )}
                                        {mentor.status === 'Connected' && (
                                            <Button color="success" className="mt-2" disabled>
                                                Connected
                                            </Button>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default RequestMentorship;
