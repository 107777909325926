import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { FaRegEye } from "react-icons/fa";
import { FaThumbsUp } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { FaEdit } from "react-icons/fa";
const ReadStory = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const storyData = {
        title: "The Enchanted Forest",
        author: "John Doe",
        genres: ["Fantasy", "Adventure"],
        Status: "Published",
        TargetAudience: "young, adults",
        Views: "120",
        Likes: "45",
        coverImage: "https://picsum.photos/200/300", // Placeholder image URL
        content: `Once upon a time in an enchanted forest, a young adventurer set out to explore hidden secrets 
        that lay within the tall trees and vibrant meadows. His journey was filled with mystical creatures, 
        magical spells, and the courage to face unknown challenges... 
        Once upon a time in an enchanted forest, a young adventurer set out to explore hidden secrets 
        that lay within the tall trees and vibrant meadows. His journey was filled with mystical creatures, 
        magical spells, and the courage to face unknown challenges...`,
    };
    const handleBack = () => {
        navigate(`/writer/dashboard`);
      };
      const handleEdit = (id) => {
        navigate(`/story/${id}/editstory`);
      };
    return (
        <Container className="read-story-container mt-5 mb-4">
            <Row className="story-content-row">
                <Col md={4} className='d-flex justify-content-center align-items-center'>
                    <img src={storyData.coverImage} alt="Cover" className="cover-image1" />
                </Col>
                <Col md={8}>
                    <div className="story-header">
                        <h1 className="story-title">{storyData.title}</h1>
                        <h5 className="story-author">By {storyData.author}</h5>
                        <div className="story-genres">
                            {storyData.genres.map((genre, index) => (
                                <span key={index} className="genre-badge">{genre}</span>
                            ))}
                        </div>
                    </div>
                    <div className="story-details">
                        <h5 className="detail-item"><strong>Status:</strong> {storyData.Status}</h5>
                        <h5 className="detail-item"><strong>Target Audience:</strong> {storyData.TargetAudience}</h5>
                        <h5 className="detail-item"><FaRegEye className="icon" /> {storyData.Views} Views</h5>
                        <h5 className="detail-item"><FaThumbsUp className="icon" /> {storyData.Likes} Likes</h5>
                    </div>
                </Col>
                <div className="mt-5">
                    <h4>Description</h4>
                    <div className="story-content mt-2 p-3">
                        <p>{storyData.content}</p>
                    </div>
                </div>
            </Row>

            <div className="back-button-container">
            <Button className='edit-btn '  onClick={() => handleEdit(id)}>
               <FaEdit size={20} /> Edit Story
                      </Button>{' '}
                <Button className="back-button"  onClick={() => handleBack()}>
                    Back to Stories
                </Button>
               
            </div>
        </Container>
    );
};

export default ReadStory;
