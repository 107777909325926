import React, { useState } from 'react';
import { Button, Container, Row, Col, Card, CardBody, CardTitle, Spinner } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';

const AnonymizedSummary = ({ toolsData, setToolsData }) => {
    const [selectedHistory, setSelectedHistory] = useState(null);
    const [isGenerating, setIsGenerating] = useState(false); // To control the loading state

    const handleGeneratePrompt = () => {
        setIsGenerating(true); 
        setTimeout(() => {
            const newEntry = {
                title: `Three mistakes in my life `, 
                output: `The AI Story Assistant suggests:
                ▪ Conflict Enhancement: Introduce a subplot where a rival scientist tries to steal the formula.
                ▪ Character Motivation: Add a backstory where the protagonist’s motivation for reversing time is rooted in a personal tragedy.
                ▪ Ending Variations: Offer alternate endings, such as a bittersweet conclusion where the formula is destroyed.`,
                timestamp: new Date().toLocaleString(),
            };

  
            setToolsData((prevState) => ({
                ...prevState,
                anonymizedSummary: [...prevState.anonymizedSummary, newEntry],
            }));

       
            setSelectedHistory(newEntry);
            setIsGenerating(false); 
        }, 2000); 
    };

    const handleDeleteHistory = (index) => {
        const updatedHistory = toolsData.anonymizedSummary.filter((_, i) => i !== index);

        setToolsData((prevState) => ({
            ...prevState,
            anonymizedSummary: updatedHistory,
        }));

        if (selectedHistory && toolsData.anonymizedSummary[index] === selectedHistory) {
            setSelectedHistory(null);
        }
    };

    const history = toolsData.anonymizedSummary || [];

    return (
        <Container fluid className="anonymized-summary-container">
            <Card className="mb-4 shadow-sm">
                <CardBody>
                    <CardTitle tag="h4" className="d-flex justify-content-between align-items-center">
                        <span className="ai-card-title">Anonymized Summary</span>
                        <Button
                            onClick={handleGeneratePrompt}
                            className="ai-generate-btn"
                            disabled={isGenerating} // Disable button while generating
                        >
                            {isGenerating ? (
                                <Spinner size="sm" /> // Show spinner when generating
                            ) : (
                                'Generate Summary'
                            )}
                        </Button>
                    </CardTitle>
                    
                </CardBody>
            </Card>
            <Row>
                {/* Sidebar for History */}
                <Col md="3" className="sidebar1  p-3 border-end">
                    <h5 className="text-center History mb-4">History</h5>
                    <div className="history-list">
                        {history.length > 0 ? (
                            history.map((item, index) => (
                                <div
                                    key={index}
                                    className={`history-item p-2 mb-2 d-flex align-items-center justify-content-between ${selectedHistory === item ? "selected" : "bg-white"
                                        }`}
                                    onClick={() => setSelectedHistory(item)}
                                >
                                    <div className="history-title font-weight-bold small">
                                        {item.title} {/* Displaying title */}
                                    </div>
                                    <Button
                                        size="sm"
                                        color="danger"
                                        className="delete-button ms-2"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteHistory(index);
                                        }}
                                    >
                                        <FaTrash size={12} />
                                    </Button>
                                </div>
                            ))
                        ) : (
                            <div className="no-history text-center text-muted">
                                No history available.
                            </div>
                        )}
                    </div>
                </Col>

                {/* Main Content */}
                <Col md="9" className="main-content p-4">


                    {/* Displaying Response */}
                    <div className="response-display p-4 ">
                        <h5 className="text-secondary">Generated Story</h5>
                        {selectedHistory ? (
                            <div className="story-container">
                                <div className="response-system">
                                    <strong>Story Title:</strong>
                                    <p>{selectedHistory.title}</p>
                                    <strong>Suggestions:</strong>
                                    <p>{selectedHistory.output}</p>
                                    <strong>Timestamp:</strong>
                                    <p>{selectedHistory.timestamp}</p>
                                </div>
                            </div>
                        ) : (
                            <div className="no-selection text-center text-muted">
                                Select a history item to view details.
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default AnonymizedSummary;
