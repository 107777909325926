import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

const Sidebar = ({ activeTab, toggleTab }) => {
    return (
        <Nav vertical className="sidebar">
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => toggleTab('1')}
                >
                    ABOUT STORY
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => toggleTab('2')}
                >
                    Story editor TOOL
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => toggleTab('3')}
                >
                    Anonymized Summary
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '9' })}
                    onClick={() => toggleTab('9')}
                >
                   AI Story Structure Analysis
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '4' })}
                    onClick={() => toggleTab('4')}
                >
                    Character Development
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '5' })}
                    onClick={() => toggleTab('5')}
                >
                   	Scene Breakdown 
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '6' })}
                    onClick={() => toggleTab('6')}
                >
                   	Screenplay Formatting
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '7' })}
                    onClick={() => toggleTab('7')}
                >
                   	Audience Potential 
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '8' })}
                    onClick={() => toggleTab('8')}
                >
                   Plagiarism Check
                </NavLink>
            </NavItem>
            
        </Nav>
    );
};

export default Sidebar;

