
import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { Collapse, Navbar as ReactstrapNavbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, } from 'reactstrap';
import logo from "../assets/images/logodummy.png"
import { storylogo } from "../theme/Images"
import { FaUserCircle } from 'react-icons/fa';
import { IoMdNotifications } from "react-icons/io";

const Navbar = () => {
  const { user, setUser } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Get the current route
  const { logout } = useAuth();
  const handleLogout = () => {
    // Clear user data and redirect to login
    // localStorage.removeItem('user');
    // setUser(null);
    // navigate('/login');
    logout();
  navigate('/login');
  };

  const toggle = () => setIsOpen(!isOpen);

  return (
    <ReactstrapNavbar expand="md" className="Navbar-main fixed-top" >
      <NavbarBrand tag={Link} to="/"><img src={storylogo} alt='img' style={{ width: "200px", height: "45px" }} /></NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ms-auto" navbar>
          {!user && (
            <>
              <NavItem className="me-3">
                <NavLink
                  tag={Link}
                  to="/"
                  className={location.pathname === '/' ? 'active' : ''}
                >
                  Home
                </NavLink>
              </NavItem>
              <NavItem className="me-3">
                <NavLink
                  tag={Link}
                  to="/about"
                  className={location.pathname === '/about' ? 'active' : ''}
                >
                  About
                </NavLink>
              </NavItem>
              <NavItem className="me-3">
                <NavLink
                  tag={Link}
                  to="/contact"
                  className={location.pathname === '/contact' ? 'active' : ''}
                >
                  Contact Us
                </NavLink>
              </NavItem>
              <NavItem className="me-3">
                <NavLink
                  tag={Link}
                  to="/service-offerings"
                  className={location.pathname === '/service-offerings' ? 'active' : ''}
                >
                  Service Offerings
                </NavLink>
              </NavItem>
            </>
          )}
          {/* Show dashboard links based on user role */}
          {user && user.role === 'writer' && (
            <>
              <NavItem className="me-3">
                <NavLink
                  tag={Link}
                  to="/writer/dashboard"
                  className={location.pathname === '/writer/dashboard' ? 'active' : ''}
                >
                  My Stories 
                </NavLink>
              </NavItem>
              <NavItem className="me-3">
                <NavLink tag={Link} to="/writer/create-story" className={location.pathname === '/writer/create-story' ? 'active' : ''}>
                  Create Story
                </NavLink>
              </NavItem>
            </>
          )}
          {user && user.role === 'producer' && (
            <NavItem className="me-3">
              <NavLink
                tag={Link}
                to="/producer/dashboard"
                className={location.pathname === '/producer/dashboard' ? 'active' : ''}
              >
                Producer Dashboard
              </NavLink>
            </NavItem>
          )}
          {user && user.role === 'mentor' && (
            <NavItem className="me-3">
              <NavLink
                tag={Link}
                to="/mentor/dashboard"
                className={location.pathname === '/mentor/dashboard' ? 'active' : ''}
              >
                Mentor Dashboard
              </NavLink>
            </NavItem>
          )}
          {user && user.role === 'writer' && (
            <NavItem className="me-3">
              <NavLink
                tag={Link}
              >
                <IoMdNotifications className="notification-icon" />
              </NavLink>
            </NavItem>
          )}
        </Nav>

        {/* Right side: Profile dropdown or login */}
        <Nav navbar className="ms-3">
          {user ? (
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav>
                <div className="profile-section">
                  {/* Conditionally render profile picture or dummy icon */}
                  {user.profilePicture ? (
                    <img
                      src={user.profilePicture}
                      alt="Profile"
                      className="profile-pic"
                    />
                  ) : (
                    <FaUserCircle className="dummy-profile-icon" />
                  )}
                  <div className="profile-info">
                    {user.email} <br />
                    <small className="user-role">{user.role}</small>
                  </div>
                </div>
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem tag={Link} to="/profile">
                  My Profile
                </DropdownItem>
                <DropdownItem tag={Link} to="/profile/bio">
                  Bio
                </DropdownItem>
                <DropdownItem tag={Link} to="/profile/change-password">
                  Change Password
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={handleLogout}>
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : (
            <NavItem>
              <NavLink tag={Link} to="/login">
                <Button className='login-btn'>Login</Button>
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </Collapse>
    </ReactstrapNavbar>
  );
};

export default Navbar;
