import React from 'react';
import { Container, Row, Col, Button, Card, CardBody, CardTitle, CardText, CardImg } from 'reactstrap';
import { useNavigate, Link } from 'react-router-dom';
import { aboutimg, vision, mentor, producericon, writerIcon } from "../theme/Images"
function About() {




    return (
        <div>
            <div className="mainseaction">
                <Container className="py-5">
                    <h1 className=" text-center py-3">ABOUT THE KALAMKAAR</h1>

                    <Row className="">
                        <Col md="5" className=''>
                            <Card className='p-2 aboutcard'>
                                <CardBody>
                                    <CardImg src={aboutimg} alt="Card image" className='img-fluid' />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="7">
                            <h2 className="h2-title">OVERVIEW</h2>
                            <p>At Kalamkaar, we believe in the power of storytelling and its ability to connect,
                                inspire, and transform. Our mission is to bring together passionate writers,
                                experienced mentors, and creative producers in a collaborative space where
                                ideas can flourish and careers can grow.</p>

                            <p> Whether you're a writer looking for guidance, a producer searching for the next
                                big hit, or a mentor eager to share your expertise, Kalamkaar is the ultimate
                                platform designed to help you succeed.</p>

                            <p>Through our user-friendly interface, we offer seamless tools for story submission,
                                contract management, mentorship,  and more, empowering creators to navigate
                                the world of storytelling with confidence.</p>
                        </Col>
                    </Row>

                </Container>
            </div>
            <section className='vision-section'>
                <Container className="">
                    <Row className="">
                        <Col md="6" className='py-5'>
                            <h2 className="h2-title">OUR VISION</h2>
                            <p>To build a dynamic ecosystem that fosters creativity, supports professional growth.</p>

                            <p>  makes the storytelling process accessible to everyone—whether you’re just starting
                                out or you're a seasoned industry expert.</p>

                            <p> We aim to bridge the gap between writers, producers, and mentors to create
                                impactful stories that reach and resonate with audiences worldwide.</p>
                        </Col>
                        <Col md="6">
                            <Card className='p-2 aboutcard'>
                                <CardBody>
                                    <CardImg src={vision} alt="Card image" className='img-fluid' />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>

            </section>
            <section style={{ backgroundColor: "#DFE1E7" }}>
                <Container className="">
                    <h2 className="h2-title text-center">WHAT WE OFFER</h2>
                    <Row className="py-3">
                        <Col md="4" className=''>
                            <Card className='h-100 vision-card'>
                                <CardBody className='text-center'>
                                    <CardImg src={writerIcon} alt="Card image" className='img-fluid aboutCard' />
                                    <CardTitle tag="h4" className='vision-card-h'>For Writers</CardTitle>
                                    <CardText className='vison-card-p px-2'> A space to showcase your creativity,
                                        receive feedback, connect with mentors,
                                        and find opportunities to get your
                                        work in front of producers.</CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4" >
                            <Card className='h-100 vision-card'>
                                <CardBody className='text-center'>
                                    <CardImg src={producericon} alt="Card image" className='img-fluid aboutCard' />
                                    <CardTitle tag="h4" className='vision-card-h'>For Producers</CardTitle>
                                    <CardText className='vison-card-p px-2'>A curated collection of stories to discover, express interest in, and sign contracts with talented writers.
                                        You’ll have the ability to connect with mentors and ensure the success of your projects.</CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4" >
                            <Card className='h-100 vision-card'>
                                <CardBody className='text-center'>
                                    <CardImg src={mentor} alt="Card image" className='img-fluid aboutCard' />
                                    <CardTitle tag="h4" className='vision-card-h'>For Mentors</CardTitle>
                                    <CardText className='vison-card-p px-2'> An opportunity to guide aspiring writers and producers, sharing your expertise
                                        and helping shape the next generation of storytellers.</CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
            <div style={{ backgroundColor: "#E9F3FD" }} className='m-0'>
                <Container className=''>
                   <div>
                     <h5 className="h2-title text-center m-0">JOIN US</h5> 
                     <p className='text-center m-0 p-3'>At Kalamkaar, we’re more than just a platform—we’re a community of storytellers. Whether you’re looking to share your story with the world, find mentorship,
                        or collaborate with like-minded professionals, we’re here to help you turn your vision into reality.</p>
                     </div>
                    
                </Container>
            </div>
        </div>
    )
}

export default About;

