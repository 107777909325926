import React, { useState } from "react";
import { Button, Spinner, Card, CardBody, CardTitle } from "reactstrap";


const AIStoryStructureAnalysis = ({ storyId, fetchAnalysis }) => {
    const [loading, setLoading] = useState(false);
    const [analysis, setAnalysis] = useState([]);
    const [error, setError] = useState("");

    const handleGenerateAnalysis = async () => {
        setLoading(true);
        setError("");
        setAnalysis([]);

        const result = await fetchAnalysis(storyId);

        if (result.success) {
            setAnalysis(result.data);
        } else {
            setError("Failed to fetch story structure analysis. Please try again.");
        }

        setLoading(false);
    };

    return (
        <div className="ai-story-analysis-container">
            <Card className="ai-story-card shadow-sm">
                <CardBody>
                    <div className="ai-header-container">
                        <CardTitle tag="h4" className="ai-card-title">
                            AI Story Structure Analysis
                        </CardTitle>
                        <Button
                            color="dark"    
                            onClick={handleGenerateAnalysis}
                            disabled={loading}
                            className="ai-generate-btn"
                        >
                            {loading ? <Spinner size="sm" /> : "Generate Analysis"}
                        </Button>
                    </div>
                    <hr className="ai-divider" />
                    <h4>Response :</h4>
                    <div className="analysis-results mt-4">
                        {loading && (
                            <div className="loading-message text-center">
                                Fetching story structure analysis...
                            </div>
                        )}
                        {error && (
                            <div className="error-message text-center text-danger">
                                {error}
                            </div>
                        )}
                        {!loading && analysis.length > 0 && (
                            <div>
                                {analysis.map((item, index) => (
                                    <div key={index} className="analysis-item mb-4">
                                        <h5 className="structure-title">{item.structure}</h5>
                                        <ul className="outline-list">
                                            {item.outline.map((point, i) => (
                                                <li key={i} className="outline-item">
                                                    {point}
                                                </li>
                                            ))}
                                        </ul>
                                        <p className="recommendation">
                                            <strong>Recommendation:</strong> {item.recommendation}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {!loading && analysis.length === 0 && !error && (
                        <div className="no-analysis-message text-center text-muted">
                            No analysis generated yet.
                        </div>
                    )}
                </CardBody>
            </Card>
        </div>
    );
};

export default AIStoryStructureAnalysis;
