
import React from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';
const AIPlagiarismCheck = ({ match }) => {
    const { id } = useParams();
  const handleCheck = () => {
    // Logic for running the plagiarism check
    console.log(`Running plagiarism check for story ${id}`);
    // You can add further functionality here
  };
  
  return (
    <div>
      <h2>Plagiarism Check for Story {id}</h2>
      <Button onClick={handleCheck}>Run Plagiarism Check</Button>
      {/* Add plagiarism check progress and results */}
    </div>
  );
};

export default AIPlagiarismCheck;