import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import CreatableSelect from 'react-select/creatable';

const CreateStory = () => {
    const [storyName, setStoryName] = useState('');
    const [authorName, setAuthorName] = useState('')
    const [genre, setGenre] = useState('');
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [targetAudience, setTargetAudience] = useState('');
    const [price, setPrice] = useState('');
    const [coverImage, setCoverImage] = useState(null);
    const genreOptions = [
        { value: 'Drama', label: 'Drama' },
        { value: 'Comedy', label: 'Comedy' },
        { value: 'Thriller', label: 'Thriller' },
        { value: 'Romance', label: 'Romance' },
        { value: 'Sci-Fi', label: 'Sci-Fi' },
        { value: 'Fantasy', label: 'Fantasy' },
        // Add more genres as required
    ];
    const handleSaveStory = () => {
        const storyData = {
            storyName,
            authorName,
            genre: genre.map((g) => g.value), // Collecting selected genres
            coverImage,
            storyContent: editorState, // Story content (from editor state)
            targetAudience,
            price,
          };
      
          // Here, you can send the storyData to an API or save it in a database
          console.log("Story saved:", storyData);
      
          // Optionally, clear the form after saving
          setStoryName('');
          setAuthorName('');
          setGenre([]);
          setCoverImage(null);
          setEditorState('');
          setTargetAudience('');
          setPrice('');
    };

    const handleCancel = () => {
        // Add your cancel logic here
    };

    return (
        <Container className=' '>
            <div className="create-story-container p-4">
                <h2 className=" ">Create New Story</h2>
                <Form className="create-story-form">
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="storyName">Story Name</Label>
                                <Input
                                    type="text"
                                    id="storyName"
                                    value={storyName}
                                    placeholder="Enter your story's title"
                                    onChange={(e) => setStoryName(e.target.value)}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="storyName">Author Name</Label>
                                <Input
                                    type="text"
                                    id="storyName"
                                    value={authorName}
                                    placeholder="Enter Author Name"
                                    onChange={(e) => setAuthorName(e.target.value)}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="genre" className="input-label">Genre</Label>
                                <CreatableSelect
                                    isMulti
                                    name="genre"
                                    options={genreOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    value={genre}
                                    onChange={(selectedOptions) => setGenre(selectedOptions)}
                                    placeholder="Select  genres"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="coverImage">Cover Image</Label>
                                <Input
                                    type="file"
                                    id="coverImage"
                                    onChange={(e) => setCoverImage(e.target.files[0])}
                                    accept="image/*"
                                />
                            </FormGroup>
                        </Col>

                    </Row>

                    <FormGroup>
                        <Label>Story Content</Label>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={setEditorState}
                            wrapperClassName="editor-wrapper" 
                            editorClassName="editor"
                            toolbarClassName="editor-toolbar"
                            placeholder="Start writing your story here..."
                        />
                    </FormGroup>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="targetAudience">Target Audience</Label>
                                <Input
                                    type="text"
                                    id="targetAudience"
                                    value={targetAudience}
                                    placeholder="Describe your target audience (e.g., young adults, family)"
                                    onChange={(e) => setTargetAudience(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="price">Price </Label>
                                <Input
                                    type="number"
                                    id="price"
                                    value={price}
                                    placeholder="Enter price for the story (optional)"
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <div className="text-end mt-4">
                        <Button
                            onClick={handleSaveStory}
                            className="me-3 custom-save-button"
                        >
                            Save Story
                        </Button>
                        <Button
                            onClick={handleCancel}
                            className="custom-cancel-button"
                        >
                            Cancel
                        </Button>
                    </div>
                </Form>
            </div>
        </Container>
    );
};

export default CreateStory;
