import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FcEditImage } from "react-icons/fc";
import Sidebar from '../../../components/Sidebar';
import CharacterDev from './AiTools/characterDev';
import AnonymizedSummary from './AiTools/AnonymizedSum';
import SceneBreakdown from './AiTools/SceneBreakdown';
import ScreenplayFormatting from './AiTools/ScreenplayFormat';
import AudiencePotentialAnalysis from './AiTools/AudiencePotential';
import AIStoryStructureAnalysis from './AiTools/StoryStructureAnalysis';
import { IoIosCreate } from "react-icons/io";
import { MdDelete } from "react-icons/md";
const EditStoryScreen = ({ handleUpdateStory, handleCancel }) => {
    const [storyName, setStoryName] = useState("Sample Story Title");
    const [authorName, setAuthorName] = useState("Chandradeep Bhoite");
    const [contentType, setContentType] = useState("Youtub content")
    const [genre, setGenre] = useState([{ label: "Fiction", value: "fiction" }]);
    const [coverImage, setCoverImage] = useState(null);
    const [coverImagePreviewUrl, setCoverImagePreviewUrl] = useState('');
    const [editorState, setEditorState] = useState('');
    const [targetAudience, setTargetAudience] = useState("Young Adults");
    const [price, setPrice] = useState("10");
    const [activeTab, setActiveTab] = useState('1');
    const [modal, setModal] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const toggle = () => setModal(!modal);

    const toggleConfirmation = () => setConfirmationModal(!confirmationModal);
    const handleConfirm = () => {
        alert("Story submitted successfully!"); // Replace with API or actual submit logic
        toggleConfirmation(); // Close confirmation modal
        toggle(); // Close main modal
    };
    const [toolsData, setToolsData] = useState({
        anonymizedSummary: [],
        characterDevelopment: [],
        sceneBreakdown: [],
        screenplayFormatting: [],
        audienceAnalysis: [],
        plagiarismCheck: []
    });
    const storyId = '12345';
    const fetchAnalysis = async (id) => {
        // Simulating API call
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve({
                    success: true,
                    data: [
                        {
                            structure: "Linear Narrative",
                            outline: [
                                "A straightforward chronological format from beginning to end.",
                                "Setup: The clockmaker is introduced as a meticulous craftsman living a quiet life.",
                                "Inciting Incident: He discovers the timepiece.",
                                "Conflict: The more he uses it, the more he sees futures he wishes to avoid, leading to obsessive behavior.",
                                "Climax: He faces a final choice—save himself or use the timepiece one last time to save someone he loves.",
                                "Resolution: He destroys the timepiece but is left with irreversible consequences.",
                            ],
                            recommendation: "Best for character-driven dramas or traditional storytelling.",
                        },
                        {
                            structure: "Three-Act Structure",
                            outline: [
                                "Divides the story into setup, confrontation, and resolution.",
                                "Act 1: Introduces the clockmaker, his life, and the discovery of the timepiece.",
                                "Act 2: Explores the escalating consequences of using the timepiece, introducing an antagonist.",
                                "Act 3: Concludes with the clockmaker making a moral decision and resolving the central conflict.",
                            ],
                            recommendation: "Ideal for films and plays where pacing and audience engagement are critical.",
                        },
                        {
                            structure: "Multiple Timelines",
                            outline: [
                                "Interweaves different timelines to reveal the story dynamically.",
                                "Timeline A: The clockmaker’s present-day struggle with the timepiece.",
                                "Timeline B: Flashbacks to his youth, explaining his obsession with time and loss.",
                                "Timeline C: Glimpses into the alternate futures he sees through the timepiece.",
                            ],
                            recommendation: "Suitable for suspense, thrillers, or stories with complex emotional backstories.",
                        },
                    ],
                });
            }, 2000)
        );
    };

    const [anonymizedSummaryInput, setAnonymizedSummaryInput] = useState("");
    const [characterDevelopmentInput, setCharacterDevelopmentInput] = useState("");
    const coverImageInputRef = useRef(null);

    // Handle cover image preview
    useEffect(() => {
        if (coverImage) {
            const reader = new FileReader();
            reader.onloadend = () => setCoverImagePreviewUrl(reader.result);
            reader.readAsDataURL(coverImage);
        }
    }, [coverImage]);

    const handleCoverImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setCoverImage(file);
        }
    };

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    return (

        <div className="edit-story-container ">
            <Container fluid className='py-2 m-0'>
                <Row>

                    <Col md={2} className=' p-0 '>
                        <Sidebar activeTab={activeTab} toggleTab={toggleTab} />
                    </Col>

                    <Col md={10}>
                        <div className="edit-story-form mt-3 ">
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <Form className="story-form">
                                        <h2>Edit Story</h2>
                                        <Row>
                                            <Col md={4} className="cover-image-container d-flex justify-content-center align-items-center">
                                                <div className="cover-image-wrapper">
                                                    <img src={coverImagePreviewUrl || "https://via.placeholder.com/150"} alt="Cover" className="cover-image" />
                                                    <div className="edit-icon-overlay" onClick={() => coverImageInputRef.current.click()}>
                                                        <FcEditImage size={20} />
                                                    </div>
                                                    <input
                                                        type="file"
                                                        id="coverImage"
                                                        onChange={handleCoverImageChange}
                                                        accept="image/*"
                                                        style={{ display: 'none' }}
                                                        ref={coverImageInputRef}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="storyName" className="input-label">Story Name *</Label>
                                                    <Input
                                                        type="text"
                                                        id="storyName"
                                                        value={storyName}
                                                        placeholder="Enter your story's title"
                                                        onChange={(e) => setStoryName(e.target.value)}
                                                        required
                                                        className="custom-input"
                                                    />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label for="authorName" className="input-label">Author's Name *</Label>
                                                    <Input
                                                        type="text"
                                                        id="authorName"
                                                        value={authorName}
                                                        placeholder="Enter Author Name"
                                                        onChange={(e) => setAuthorName(e.target.value)}
                                                        required
                                                        className="custom-input"
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="authorName" className="input-label">Content type  *</Label>
                                                    <Input
                                                        type="text"
                                                        id="contentType"
                                                        value={contentType}
                                                        placeholder="ex- Song Writing, Youtube Content, "
                                                        onChange={(e) => setContentType(e.target.value)}
                                                        required
                                                        className="custom-input"
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="genre" className="input-label">Genre *</Label>
                                                    <CreatableSelect
                                                        name="genre"
                                                        options={[
                                                            { label: "Fiction", value: "fiction" },
                                                            { label: "Non-Fiction", value: "non-fiction" },
                                                            { label: "Adventure", value: "adventure" }
                                                        ]}
                                                        value={genre}
                                                        onChange={(selectedOptions) => setGenre(selectedOptions)}
                                                        placeholder="Select genres"
                                                        className="basic-multi-select custom-select"
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="targetAudience">Target Audience</Label>
                                                    <Input
                                                        type="text"
                                                        id="targetAudience"
                                                        value={targetAudience}
                                                        placeholder="Describe your target audience"
                                                        onChange={(e) => setTargetAudience(e.target.value)}
                                                    />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label for="price">Price</Label>
                                                    <Input
                                                        type="number"
                                                        id="price"
                                                        value={price}
                                                        placeholder="Enter price"
                                                        onChange={(e) => setPrice(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <div className="mt-4 d-md-flex justify-content-md-end">
                                        <Button onClick={() => handleUpdateStory({
                                            storyName,
                                            authorName,
                                            genre,
                                            targetAudience,
                                            price
                                        })} className="me-2 btn-editsave">
                                            Save Changes
                                        </Button>
                                        <Button onClick={handleCancel} className='btn-cancelEdit'>
                                            Cancel
                                        </Button>
                                    </div>
                                </TabPane>

                                <TabPane tabId="2">
                                    <h2>Edit Story</h2>
                                    <FormGroup>
                                        <Label className="story-container mt-5">Story Content</Label>
                                        <Button onClick={() => handleUpdateStory({
                                            storyName,
                                            authorName,
                                            genre,
                                            targetAudience,
                                            price
                                        })} className="me-2 btn-editsave float-end">
                                            Upload Story
                                        </Button>
                                        <Button className="me-2 btn-editsave float-end" onClick={toggle}>
                                            Log Line
                                        </Button>
                                        <Editor
                                            editorState={editorState}
                                            onEditorStateChange={setEditorState}
                                            wrapperClassName="editor-wrapper"
                                            editorClassName="editor"
                                            toolbarClassName="editor-toolbar"
                                            placeholder="Start editing your story here..."
                                        />
                                    </FormGroup>
                                    <div className="mt-4 d-md-flex justify-content-md-end">
                                        <Button onClick={() => handleUpdateStory({
                                            storyName,
                                            authorName,
                                            genre,
                                            targetAudience,
                                            price
                                        })} color="primary" className="me-2 btn-editsave">
                                            Save Changes
                                        </Button>
                                        <Button onClick={handleCancel} className='btn-cancelEdit'>
                                            Cancel
                                        </Button>
                                    </div>
                                </TabPane>

                                <TabPane tabId="3">
                                    <AnonymizedSummary toolsData={toolsData} setToolsData={setToolsData} />
                                </TabPane>

                                <TabPane tabId="4">
                                    <CharacterDev toolsData={toolsData} setToolsData={setToolsData} />
                                </TabPane>
                                <TabPane tabId="5">
                                    <SceneBreakdown toolsData={toolsData} setToolsData={setToolsData} />
                                </TabPane>
                                <TabPane tabId="6">
                                    <ScreenplayFormatting toolsData={toolsData} setToolsData={setToolsData} />
                                </TabPane>
                                <TabPane tabId="7">
                                    <AudiencePotentialAnalysis toolsData={toolsData} setToolsData={setToolsData} />
                                </TabPane>
                                <TabPane tabId="9">
                                    {/* <AIStoryStructureAnalysis toolsData={toolsData} setToolsData={setToolsData} /> */}
                                    <AIStoryStructureAnalysis storyId={storyId} fetchAnalysis={fetchAnalysis} />
                                </TabPane>
                            </TabContent>


                        </div>
                    </Col>
                </Row>
                <Modal isOpen={modal} toggle={toggle} className="logline-custom-modal">
                    {/* <ModalHeader toggle={toggle} className="logline-modal-header">
                      
                    </ModalHeader> */}
                    <ModalBody className="logline-modal-body">
                        <h4 className='modal1-header text-center'> <IoIosCreate size={77} color='#6a5db4' /></h4>
                        <h4 className='modal1-header text-center'> Create Story from log line</h4>
                        <div className="logline-form-group">
                            <Label for="input1" className="logline-form-label">Outline: </Label>
                            <Input id="input1" type="textarea" className="logline-form-input" placeholder="Enter Story Outline" />
                        </div>
                        <div className="logline-form-group">
                            <Label for="input2" className="logline-form-label">Product: </Label>
                            <Input id="input2" type="text" className="logline-form-input" placeholder="Enter more text here" />
                        </div>
                    </ModalBody>
                    <ModalFooter className="logline-modal-footer">
                        <Button color="primary" className="logline-submit-btn" onClick={toggleConfirmation}>Submit</Button>
                        <Button color="secondary" className="logline-cancel-btn" onClick={toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                 {/* Confirmation Modal */}
                <Modal isOpen={confirmationModal} toggle={toggleConfirmation} className="logline-confirmation-modal">
                    {/* <ModalHeader className="logline-confirmation-header">
                        Confirmation
                    </ModalHeader> */}
                    <ModalBody className="logline-confirmation-body">
                    <MdDelete size={77} color='#f74242'/> <br></br> Are you sure? <br></br>The previous story will be deleted.
                    </ModalBody>
                    <div className="logline-confirmation-footer py-4">
                        <Button
                            color="danger"
                            className="logline-confirm-btn"
                            onClick={handleConfirm}
                        >
                            Confirm
                        </Button>
                        <Button
                            color="secondary"
                            className="logline-cancel-confirm-btn"
                            onClick={toggleConfirmation}
                        >
                            Cancel
                        </Button>
                    </div>
                </Modal>
            </Container>
        </div>
    );
};

export default EditStoryScreen;
