import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './router';
import Navbar from './components/navbar';
import Footer from './components/Footer';
import "./assets/style/style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from './hooks/useAuth';
const App = () => (

  <AuthProvider>
    <Router>
      <Navbar />
      <div className="main-content">
        <AppRouter />
      </div>
      <Footer />
    </Router>
  </AuthProvider>
);

export default App;
