import React,{ useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const ProtectedRoute = ({ element, allowedRoles }) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user !== null) setLoading(false);
  }, [user]);

  if (loading) return null; // Add a loading spinner if desired

  return user && allowedRoles.includes(user.role) ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
