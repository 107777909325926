// import React, { useState } from 'react';
// import { Container, Row, Col, Button, TabContent, TabPane, Input, FormGroup, Label } from 'reactstrap';
// import '../../assets/style/style.css'
// import { FaAddressBook } from "react-icons/fa6";
// import { BsEye,BsEyeSlash  } from "react-icons/bs";
// function Register() {
//      const [showPassword, setShowPassword] = useState(false);

//     // Toggle password visibility
//     const togglePasswordVisibility = () => {
//        setShowPassword(prevState => !prevState);
//     };
//     return (
//         <div className="register ">
//             <Container className="py-3">
//                 <Row>
//                     <Col md="3" className="register-left d-flex flex-column align-items-center justify-content-center">
//                         <FaAddressBook className='userbook' />
//                         <h1 className='register-head'>Welcome</h1>
//                         <p className='register-head1'> "Join a vibrant community of writers, producers, and mentors. Connect, collaborate, and grow your craft together."</p>
//                         {/* <Button type="submit">Login</Button><br /> */}
//                     </Col>
//                     <Col md="9" className="register-right">
//                         {/* <TabContent activeTab={activeTab} id="myTabContent">
//                             <TabPane tabId="1" role="tabpanel" aria-labelledby="home-tab"> */}
//                         <h3 className="register-heading">Writer’s Registration Form</h3>
//                         <Row className="register-form">
//                             <Col md="6">
//                                 <FormGroup>
//                                     <Label for="email" className='input-label'>First Name </Label>
//                                     <Input type="text" className="form-control" placeholder="First Name *" />
//                                 </FormGroup>
//                                 <FormGroup>
//                                     <Label for="email" className='input-label'>Last Name</Label>
//                                     <Input type="text" className="form-control" placeholder="Last Name *" />
//                                 </FormGroup>
//                                 <FormGroup>
//                                     <Label for="email" className='input-label'>Password </Label>
//                                     <div style={{ position: 'relative' }}>
//                                         <Input
//                                             type={showPassword ? "text" : "password"}
//                                             className="form-control"
//                                             placeholder="Password *"
//                                             id="Password"
//                                         />
//                                         <span
//                                             onClick={togglePasswordVisibility}
//                                             style={{
//                                                 position: 'absolute',
//                                                 right: '10px',
//                                                 top: '50%',
//                                                 transform: 'translateY(-50%)',
//                                                 cursor: 'pointer',
//                                                 color: '#6c757d'
//                                             }}
//                                         >
//                                             {showPassword ? <BsEyeSlash /> : <BsEye />}
//                                         </span>
//                                     </div>
//                                 </FormGroup>
//                                 <FormGroup>
//                                     <Label for="email" className='input-label'>Confirm Password </Label>
//                                     <div style={{ position: 'relative' }}>
//                                         <Input
//                                             type={showPassword ? "text" : "password"}
//                                             className="form-control"
//                                             placeholder="Confirm Password *"
//                                             id="confirmPassword"
//                                         />
//                                         <span
//                                             onClick={togglePasswordVisibility}
//                                             style={{
//                                                 position: 'absolute',
//                                                 right: '10px',
//                                                 top: '50%',
//                                                 transform: 'translateY(-50%)',
//                                                 cursor: 'pointer',
//                                                 color: '#6c757d'
//                                             }}
//                                         >
//                                             {showPassword ? <BsEyeSlash /> : <BsEye />}
//                                         </span>
//                                     </div>
//                                 </FormGroup>
//                                 <FormGroup>
//                                     <Label for="email" className='input-label'>Bio </Label>
//                                     <Input type="textarea" className="form-control" placeholder="Boi *" />
//                                 </FormGroup>
//                             </Col>
//                             <Col md="6">
//                                 <FormGroup>
//                                     <Label for="email" className='input-label'>Email </Label>
//                                     <Input type="email" className="form-control" placeholder="Your Email *" />
//                                 </FormGroup>
//                                 <FormGroup>
//                                     <Label for="email" className='input-label'>Phone Number </Label>
//                                     <Input type="text" minLength="10" maxLength="10" name="txtEmpPhone" className="form-control" placeholder="Your Phone *" />
//                                 </FormGroup>
//                                 <FormGroup>
//                                     <Label for="email" className='input-label'>Gender </Label>
//                                     <Input type="select" className="form-control">
//                                         <option className="hidden" selected disabled>Please select your Gender</option>
//                                         <option>male</option>
//                                         <option>famale</option>
//                                         <option>other</option>
//                                     </Input>
//                                 </FormGroup>
//                                 <FormGroup>
//                                     <Label for="email" className='input-label'>Genres </Label>
//                                     <Input type="text" className="form-control" placeholder="Enter Your Genres *" />
//                                 </FormGroup>
//                                 <FormGroup>
//                                     <Label for="email" className='input-label'>Profile Photo</Label>
//                                     <Input type="file" className="form-control" />
//                                 </FormGroup>
//                                 <Button className="btnRegister">Register</Button>   
//                             </Col>
//                         </Row>
//                         {/* </TabPane>
//                         </TabContent> */}
//                     </Col>
//                 </Row>
//             </Container>
            
//         </div>
//     )
// }
// export default Register;
import React, { useState } from 'react';
import { Container, Row, Col, Button, Input, FormGroup, Label, Alert } from 'reactstrap';
import '../../assets/style/style.css';
import { FaAddressBook } from "react-icons/fa6";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { registerUser } from "../../services/authService";

const Register = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email_address: '',
        phone_number: '',
        gender: '',
        genres: '',
        password: '',
        confirm_password: '',
        bio: '',
        profile_picture: null,
        content_type: null,
    });
    const [showPassword, setShowPassword] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [loading, setLoading] = useState(false);

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    // Handle input changes
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value
        });
    };

    // Form submission handler
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        console.log('Form Data:', formData);  // Log form data to inspect what’s being sent
    
        // Basic validation
        if (formData.password !== formData.confirm_password) {
            setAlertMessage('Passwords do not match!');
            setAlertType('danger');
            return;
        }
    
        // Construct FormData for file upload
        const payload = new FormData();
        Object.keys(formData).forEach((key) => {
            payload.append(key, formData[key]);
        });
    
        setLoading(true);
        try {
            // API call to register the user
            const response = await registerUser(payload);
            console.log('API Response:', response);  // Log the full response
    
            // Check if the API call was successful
            if (response.status === 200 && response.data?.message) {
                setAlertMessage(response.data.message);
                setAlertType('success');
            } else {
                // Handle case where the API returns a 200 status but with an error message
                setAlertMessage(response.data?.message || 'Registration failed.');
                setAlertType('danger');
            }
        } catch (error) {
            console.log('Error:', error);  // Log the error details for debugging
            setAlertMessage(error.response?.data?.message || 'An error occurred during registration.');
            setAlertType('danger');
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <div className="register">
            <Container className="py-3">
                <Row>
                    <Col md="3" className="register-left d-flex flex-column align-items-center justify-content-center">
                        <FaAddressBook className='userbook' />
                        <h1 className='register-head'>Welcome</h1>
                        <p className='register-head1'>
                            "Join a vibrant community of writers, producers, and mentors. Connect, collaborate, and grow your craft together."
                        </p>
                    </Col>
                    <Col md="9" className="register-right">
                        <h3 className="register-heading">Writer’s Registration Form</h3>
                        <form onSubmit={handleSubmit} className="register-form">
                            {alertMessage && <Alert color={alertType}>{alertMessage}</Alert>}
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label className='input-label'>First Name</Label>
                                        <Input
                                            type="text"
                                            name="first_name"
                                            placeholder="First Name *"
                                            value={formData.first_name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className='input-label'>Last Name</Label>
                                        <Input
                                            type="text"
                                            name="last_name"
                                            placeholder="Last Name *"
                                            value={formData.last_name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className='input-label'>Password</Label>
                                        <div style={{ position: 'relative' }}>
                                            <Input
                                                type={showPassword ? "text" : "password"}
                                                name="password"
                                                placeholder="Password *"
                                                value={formData.password}
                                                onChange={handleChange}
                                                required
                                            />
                                            <span
                                                onClick={togglePasswordVisibility}
                                                style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    cursor: 'pointer',
                                                    color: '#6c757d'
                                                }}
                                            >
                                                {showPassword ? <BsEyeSlash /> : <BsEye />}
                                            </span>
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className='input-label'>Confirm Password</Label>
                                        <Input
                                            type={showPassword ? "text" : "password"}
                                            name="confirm_password"
                                            placeholder="Confirm Password *"
                                            value={formData.confirm_password}
                                            onChange={handleChange}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className='input-label'>Bio</Label>
                                        <Input
                                            type="textarea"
                                            name="bio"
                                            placeholder="Bio *"
                                            value={formData.bio}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label className='input-label'>Email</Label>
                                        <Input
                                            type="email"
                                            name="email_address"
                                            placeholder="Your Email *"
                                            value={formData.email_address}
                                            onChange={handleChange}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className='input-label'>Phone Number</Label>
                                        <Input
                                            type="text"
                                            name="phone_number"
                                            placeholder="Your Phone *"
                                            value={formData.phone_number}
                                            onChange={handleChange}
                                            minLength="10"
                                            maxLength="10"
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className='input-label'>Gender</Label>
                                        <Input
                                            type="select"
                                            name="gender"
                                            value={formData.gender}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option disabled value="">Please select your Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className='input-label'>Genres</Label>
                                        <Input
                                            type="text"
                                            name="genres"
                                            placeholder="Enter Your Genres *"
                                            value={formData.genres}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className='input-label'>Profile Photo</Label>
                                        <Input
                                            type="file"
                                            name="profile_picture"
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                    <Button type="submit" className="btnRegister" disabled={loading}>
                                        {loading ? 'Registering...' : 'Register'}
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Register;

