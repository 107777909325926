import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { Button, Form, FormGroup, Label, Input, Card, CardBody, Row, Col } from 'reactstrap';
import "../../assets/style/style.css"
import { FaFacebook, FaUserLock } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const { login } = useAuth();
  const handleLogin = (e) => {
    e.preventDefault();

    const fakeUser = {
      email: email,
      role: 'writer', // or 'producer', 'mentor'
    };
    login(fakeUser);
   
    if (fakeUser.role === 'writer') {
      navigate('/writer/dashboard');
    } else if (fakeUser.role === 'producer') {
      navigate('/producer/dashboard');
    } else if (fakeUser.role === 'mentor') {
      navigate('/mentor/dashboard');
    }
  };
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser)); // Restore user state from localStorage
    }
  }, []);
  return  (
    <div className='login-bg '>
      <div className="h-100 py-5 d-flex align-items-center justify-content-center container ">
        <Row className="w-100">
          <Col md="6" className="d-flex flex-column justify-content-center align-items-start p-5">
            <FaUserLock  className='log-user'/>
            <h1 className="display-h1">Log In to Your World <BiWorld/></h1>
            <h2 className="mb-4 display-h2">Start writing, collaborating, and inspiring others </h2>
            <p className="text-muted display-h3">Unlock Your <span style={{ color: '#6c63ff', fontWeight: '700' }}>Creativity</span></p>
          </Col>

          <Col md="6" className="d-flex justify-content-center align-items-center">
            <Card >
              <CardBody className='p-5'>
                <div className="text-center mb-4">
                  <h5>Welcome to StoryPortal! 👋</h5>
                  <p className="text-muted">Please sign in to your account and start the adventure</p>
                </div>
                <Form>
                  <FormGroup>
                    <Label for="email" className='input-label'>Email </Label>
                    <Input type="email" name="email" id="email" placeholder="Enter your email " value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required />
                  </FormGroup>
                  <FormGroup>
                    <Label for="password" className='input-label'>Password</Label>
                    <Input type="password" name="password" id="password" placeholder="Enter your password" value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required />
                    <small className="float-right forgot-pass">
                      <a href="#forgot-password" className="text-muted">Forgot Password?</a>
                    </small>
                  </FormGroup>
                  <FormGroup check className="mb-3">
                    <Label check>
                      <Input type="checkbox" />{' '}
                      Remember me
                    </Label>
                  </FormGroup>
                  <Button color="primary" block onClick={handleLogin}>
                    Sign In
                  </Button>
                </Form>
                <div className="text-center mt-3">
                  <p className="text-muted">
                    New on our platform? <Link to="/register">Create an account</Link>
                  </p>
                </div>
                <div className="text-center mt-3">
                  <p className="text-muted">or</p>
                  <div className=''>
                    <Button color="primary" outline className="m-1">
                      <FaFacebook />
                    </Button>
                    <Button color="danger" outline className="m-1">
                      <FaTwitter />
                    </Button>
                    <Button color="info" outline className="m-1">
                      <FaTwitter />
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;


