import React, { useState } from 'react';
import { Container, Row, Col, Input, Button, Card, CardBody, CardTitle, Label } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';

const AudiencePotentialAnalysis = ({ toolsData = { audiencePotentialAnalysis: [] }, setToolsData }) => {
    const [analysisInput, setAnalysisInput] = useState("");
    const [selectedHistory, setSelectedHistory] = useState(null);
  
    const history = toolsData.audiencePotentialAnalysis || [];
  
    const handleGenerateAnalysis = () => {
      const response = {
        demographics: "Target Demographics: 18-35 years, urban professionals.",
        appeal: "High appeal due to its relatable themes and engaging characters.",
        successMetrics: "Estimated success potential: 75% based on similar past projects.",
      };
  
      const newHistory = {
        user: analysisInput.trim(),
        response,
        timestamp: new Date().toLocaleString(),
      };
  
      setToolsData((prevState) => ({
        ...prevState,
        audiencePotentialAnalysis: [...history, newHistory],
      }));
  
      setSelectedHistory(newHistory);
      setAnalysisInput("");
    };
  
    const handleDeleteHistory = (index) => {
      const updatedHistory = history.filter((_, i) => i !== index);
  
      setToolsData((prevState) => ({
        ...prevState,
        audiencePotentialAnalysis: updatedHistory,
      }));
  
      if (selectedHistory && history[index] === selectedHistory) {
        setSelectedHistory(null);
      }
    };
  
    return (
      <Container fluid className="audience-potential-analysis-container">
        <Row>
        <Card className="mb-4 shadow-sm">
              <CardBody>
                <CardTitle tag="h4" className="head-char mb-4">
                  Audience Potential Analysis
                </CardTitle>
                <Label className='label-char'> Enter Summary</Label>
                <Input
                  type="text"
                  value={analysisInput}
                  onChange={(e) => setAnalysisInput(e.target.value)}
                  placeholder="Enter idea, script, or story description"
                  className="mb-3"
                />
                <Button onClick={handleGenerateAnalysis} className='btn-gen-resp float-end'>
                  Analyze Potential
                </Button>
              </CardBody>
            </Card>
          <Col md="3" className="sidebar1 bg-light p-3">
            <h5 className="text-center History mb-4">History</h5>
            <div className="history-list">
              {history.length > 0 ? (
                history.map((item, index) => (
                  <div
                    key={index}
                    className={`history-item p-2 mb-2 d-flex align-items-center justify-content-between ${
                      selectedHistory === item ? "selected" : ""
                    }`}
                    onClick={() => setSelectedHistory(item)}
                  >
                    <div className="history-details">
                      <div className="history-title text-truncate">
                        {item.user || "No input provided"}
                      </div>
                      <div className="history-timestamp text-muted small">
                        {item.timestamp}
                      </div>
                    </div>
                    <Button
                      size="sm"
                      color="danger"
                      className="delete-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteHistory(index);
                      }}
                    >
                      <FaTrash />
                    </Button>
                  </div>
                ))
              ) : (
                <div className="no-history text-center text-muted">No history available.</div>
              )}
            </div>
          </Col>
          <Col md="9" className="main-content p-4">
           
  
            <div className="response-display p-3 bg-white shadow-sm rounded">
              {selectedHistory ? (
                <div>
                  <div className="response-user mb-3">
                    <strong>You:</strong> {selectedHistory.user || "No input provided"}
                  </div>
                  <div className="response-system">
                    <strong>Analysis Results:</strong>
                    <ul>
                      <li><strong>Demographics:</strong> {selectedHistory.response.demographics}</li>
                      <li><strong>Appeal:</strong> {selectedHistory.response.appeal}</li>
                      <li><strong>Success Metrics:</strong> {selectedHistory.response.successMetrics}</li>
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="no-selection text-center text-muted">
                  Select a history item to view details.
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    );
  };
  

export default AudiencePotentialAnalysis;
