import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, CardText, ListGroup, ListGroupItem, Container, Row, Col, Badge, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';

const ViewFeedback = ({ match }) => {
  const { id } = useParams();
  const [feedback, setFeedback] = useState([]);

  useEffect(() => {
    fetchFeedback(id);
  }, [id]);
  const fetchFeedback = (storyId) => {
    // API call to get feedback
  };
  const [activeFeedback, setActiveFeedback] = useState('mentor');
  const feedbackData = [
    {
      type: 'mentor',
      storyName: 'The Lost Treasure',
      reviewerName: 'John Doe',
      date: '2024-11-15',
      rating: 4.5,
      comments: 'Great pacing and character development.',
      suggestions: 'Consider a stronger opening scene.',
    },
    {
      type: 'mentor',
      storyName: 'The Lost Treasure',
      reviewerName: 'Jane Smith',
      date: '2024-11-10',
      rating: 4.0,
      comments: 'Excellent descriptions of the setting.',
      suggestions: 'Simplify the ending for clarity.',
    },
    {
      type: 'producer',
      storyName: 'The Lost Treasure',
      reviewerName: 'Alex Johnson',
      date: '2024-11-12',
      rating: 4.8,
      comments: 'Very marketable story with wide appeal.',
      suggestions: 'Add a subplot for the secondary character.',
    },
  ];

  const filteredFeedback = feedbackData.filter((item) => item.type === activeFeedback);


  return (
    <div className='py-5'>

      <Container className="view-feedback">
        <h2 className="text-center">Feedback for Story {id}</h2>
        <Row>
          <Col md="3" className="border-right">
            <h5>Feedback Type</h5>
            <Button
              className="w-100 mb-2 mt-3"
              style={{
                backgroundColor: activeFeedback === 'mentor' ? '#6A5DB4' : '#E9F3FD',
                color: activeFeedback === 'mentor' ? '#fff' : '#000',
                border: 'none',
                marginBottom: '10px'
              }}
              onClick={() => setActiveFeedback('mentor')}
            >
              Mentor Feedback
            </Button>
            <Button
              className="w-100"
              style={{
                backgroundColor: activeFeedback === 'producer' ? '#6A5DB4' : '#E9F3FD',
                color: activeFeedback === 'producer' ? '#fff' : '#000',
                border: 'none',
                marginBottom: '10px'
              }}
              onClick={() => setActiveFeedback('producer')}
            >
              Producer Feedback
            </Button>
          </Col>
          <Col md="9">
            <h5>{activeFeedback === 'mentor' ? 'Mentor Feedback' : 'Producer Feedback'}</h5>
            {filteredFeedback.map((item, index) => (
              <Card key={index} className="feedback-card mb-3">
                <CardBody>
                  <h5 className="story-name">{item.storyName}</h5>
                  <div className="feedback-section">
                    <strong>Reviewer:</strong> <span>{item.reviewerName}</span>
                  </div>
                  <div className="feedback-section">
                    <strong>Date:</strong> <span>{item.date}</span>
                  </div>
                  <div className="feedback-section">
                    <div className="rating-container">
                      <strong>Rating:</strong>
                      <div className="rating-stars">⭐⭐⭐⭐⭐</div>
                      <span>({item.rating}/5)</span>
                    </div>
                  </div>
                  <div className="feedback-section">
                    <strong>Comments:</strong>
                    <p>{item.comments}</p>
                  </div>
                  <div className="feedback-section">
                    <strong>Suggestions:</strong>
                    <p>{item.suggestions}</p>
                  </div>
                </CardBody>
              </Card>
            ))}
          </Col>
        </Row>
      </Container>
    </div>

  );
};

export default ViewFeedback;
