import React, { useState } from 'react';
import { Container, Row, Col, Button, Card, CardBody, CardTitle, CardText, FormGroup, Label, Input, Badge, CardFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { book } from "../../theme/Images"
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const WriterDashboard = () => {
  const navigate = useNavigate();


  const [stories, setStories] = useState([
    { id: 1, title: 'My First Story', status: 'Published', views: 120, likes: 45, genre: ['Adventure'], rating: 4 },
    { id: 2, title: 'Untold Tales', status: 'Draft', views: 10, likes: 5, genre: ['Fantasy'], rating: 3.5 },
    { id: 3, title: 'A Day in Life', status: 'Published', views: 75, likes: 30, genre: ['Drama'], rating: 5 },
    { id: 4, title: 'Secret Missions', status: 'Published', views: 200, likes: 80, genre: ['Mystery'], rating: 2 },
    { id: 5, title: ' Three mistackes of my life ', status: 'Draft', views: 6000, likes: 60, genre: ['Mystery'], rating: 4 },
  ]);
  const [filter, setFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState({});
  const toggleDropdown = (storyId) => {
    setDropdownOpen(prevState => ({ ...prevState, [storyId]: !prevState[storyId] }));
  };
  // Filter and search
  const filteredStories = stories.filter(story =>
    (filter === 'all' || story.status.toLowerCase() === filter) &&
    story.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleDelete = (id) => {
    setStories(stories.filter(story => story.id !== id));
  };

  // const handleEdit = (id) => {
  //   navigate(`/story/${id}/editstory`);
  // };

  const handleOpenFullStory = (id) => {
    navigate(`/story/${id}/readStory`);
  };
  const handlePublish = (id) => {
    setStories(stories.map(story => story.id === id ? { ...story, status: 'Published' } : story));
  };
  const handleCheckPlagiarism = (id) => {
    navigate(`/story/${id}/plagiarism-check`);
  };

  const handleFindMentor = (id) => {
    navigate(`/story/${id}/find-mentor`);
  };

  const handleViewFeedback = (id) => {
    navigate(`/story/${id}/feedback`);
  };

  const handleSignNDA = (id) => {
    navigate(`/story/${id}/nda`);
  };
  return (
    <Container className="story-page py-5 ">
      {/* Header Section with Search and Filter */}
      <Row className="stats-panel">
        <Col>Total Stories: {stories.length}</Col>
        <Col>Published: {stories.filter(story => story.status === 'Published').length}</Col>
        <Col>Drafts: {stories.filter(story => story.status === 'Draft').length}</Col>
      </Row>
      <Row className="header-section">
        <Col md="4" className="mb-3">
          <FormGroup>
            <Label for="filterSelect">Filter by Status</Label>
            <Input
              type="select"
              id="filterSelect"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="all">All Stories</option>
              <option value="published">Published</option>
              <option value="draft">Draft</option>
            </Input>
          </FormGroup>
        </Col>
        <Col md="8" className="mb-3">
          <FormGroup>
            <Label for="searchInput">Search Stories</Label>
            <Input
              type="text"
              id="searchInput"
              placeholder="Search stories..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>

      {/* Story Cards */}
      <Row className="story-list">
        {filteredStories.map(story => (
          <Col md="6" key={story.id} className="mb-4">
            <Card className="story-card">
              <CardBody>
                <Row className="justify-content-between align-items-center">
                  {/* Left Side: Content */}
                  <Col xs="8">
                    <CardTitle tag="h5">{story.title}</CardTitle>
                    <CardText><span style={{ fontWeight: "bold" }}>Author Name:</span> chandradeep</CardText>
                    <CardText><span style={{ fontWeight: "bold" }}> Status:</span> {story.status}</CardText>
                    <CardText>Views: {story.views} | Likes: {story.likes}</CardText>
                    <div className="genre-tags">
                      {story.genre.map(tag => (
                        <Button key={tag} className="tags-text" size="sm">
                          {tag}
                        </Button>
                      ))}
                    </div>
                    <div className="action-buttons mt-3">
                      <Button className='full-btn' size="sm" onClick={() => handleOpenFullStory(story.id)}>
                        Open Full Story
                      </Button>{' '}
                      {/* <Button className='edit-btn' size="sm" onClick={() => handleEdit(story.id)}>
                        Edit
                      </Button>{' '} */}
                      {story.status === 'Draft' && (
                        <Button className='publish-btn' size="sm" onClick={() => handlePublish(story.id)}>
                          Publish
                        </Button>
                      )}
                    </div>
                  </Col>

                  {/* Right Side: Image */}
                  <Col xs="4" className="text-end">
                    <img
                      src={book} // Replace with your image source
                      alt="Story"
                      className="img-fluid"
                      style={{ maxWidth: '100%', height: 'auto' }}
                    />
                    <Dropdown isOpen={dropdownOpen[story.id]} toggle={() => toggleDropdown(story.id)} direction="left">
                      <DropdownToggle className="menu-button" tag="span">
                        <Button size="sm" className="icon-button" >
                          &#8943;
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu>
                        {/* <DropdownItem onClick={() => handleOpenFullStory(story.id)}> Open Full Story</DropdownItem>
                        <DropdownItem onClick={() => handleEdit(story.id)}> Edit</DropdownItem> */}
                        <DropdownItem onClick={() => handleDelete(story.id)}> Delete</DropdownItem>
                        {/* <DropdownItem onClick={() => handlePublish(story.id)}>Publish</DropdownItem> */}
                        <DropdownItem onClick={() => handleCheckPlagiarism(story.id)}>Check Plagiarism</DropdownItem>
                        <DropdownItem onClick={() => handleFindMentor(story.id)}>Find Mentor</DropdownItem>
                        <DropdownItem onClick={() => handleViewFeedback(story.id)}>View Feedback</DropdownItem>
                        <DropdownItem onClick={() => handleSignNDA(story.id)}>Sign NDA</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="d-flex justify-content-between align-items-center rating-footer">
                <div className="star-rating">
                  {[...Array(5)].map((_, index) => (
                    index < story.rating ? (
                      <AiFillStar key={index} size={18} color="#FFD700" /> // Filled star
                    ) : (
                      <AiOutlineStar key={index} size={18} color="#E0E0E0" /> // Outlined star
                    )
                  ))}
                </div>
                <div className="rating-text">
                  {story.rating}/5
                </div>
              </CardFooter>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default WriterDashboard;
