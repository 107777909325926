import React, { useState } from 'react';
import { Container, Row, Col, Input, Button, Card, CardBody, CardTitle, Label } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';


const SceneBreakdown = ({ toolsData, setToolsData }) => {
    const [sceneInput, setSceneInput] = useState("");
    const [selectedHistory, setSelectedHistory] = useState(null);

    // Handle scene generation
    const handleGenerateScenes = () => {
        const scenes = [
            "Scene 1: The protagonist is introduced, showcasing their mundane life. (Purpose: Exposition)",
            "Scene 2: A mysterious event disrupts the routine, creating conflict. (Purpose: Conflict)",
            "Scene 3: The protagonist decides to act, setting the story in motion. (Purpose: Resolution)",
        ];

        const newHistory = {
            user: sceneInput.trim(),
            response: scenes,
            timestamp: new Date().toLocaleString(),
        };

        // Update toolsData state
        setToolsData((prevState) => ({
            ...prevState,
            sceneBreakdown: [...prevState.sceneBreakdown, newHistory],
        }));

        setSelectedHistory(newHistory); // Auto-select the latest history
        setSceneInput(""); // Clear input field
    };

    // Delete a specific history item
    const handleDeleteHistory = (index) => {
        const updatedHistory = toolsData.sceneBreakdown.filter((_, i) => i !== index);

        setToolsData((prevState) => ({
            ...prevState,
            sceneBreakdown: updatedHistory,
        }));

        if (selectedHistory && toolsData.sceneBreakdown[index] === selectedHistory) {
            setSelectedHistory(null);
        }
    };

    return (
        <Container fluid className="scene-breakdown-container">
            <Row>
                <Card className="mb-4 shadow-sm">
                    <CardBody>
                        <CardTitle tag="h4" className=" mb-4 head-char">
                            Scene Breakdown
                        </CardTitle>
                        <Label className='label-char'>Enter Scene Details</Label>
                        <Input
                            type="text"
                            value={sceneInput}
                            onChange={(e) => setSceneInput(e.target.value)}
                            placeholder="Enter story summary"
                            className="mb-3"
                        />
                        <Button onClick={handleGenerateScenes} className='btn-gen-resp float-end'>
                            Breakdown Scenes
                        </Button>
                    </CardBody>
                </Card>

                {/* Sidebar */}
                <Col md="3" className="sidebar1 bg-light p-3">
                    <h5 className="text-center History mb-4">History</h5>
                    <div className="history-list">
                        {toolsData.sceneBreakdown.length > 0 ? (
                            toolsData.sceneBreakdown.map((item, index) => (
                                <div
                                    key={index}
                                    className={`history-item p-2 mb-2 d-flex align-items-center justify-content-between ${selectedHistory === item ? "selected" : ""
                                        }`}
                                    onClick={() => setSelectedHistory(item)}
                                >
                                    <div className="history-details">
                                        <div className="history-title text-truncate">
                                            {item.user || "No input provided"}
                                        </div>
                                        <div className="history-timestamp text-muted small">
                                            {item.timestamp}
                                        </div>
                                    </div>
                                    <Button
                                        size="sm"
                                        color="danger"
                                        className="delete-button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteHistory(index);
                                        }}
                                    >
                                        <FaTrash size={10} />
                                    </Button>
                                </div>
                            ))
                        ) : (
                            <div className="no-history text-center text-muted">No history available.</div>
                        )}
                    </div>
                </Col>

                {/* Main Content */}
                <Col md="9" className="main-content p-4">

                    <div className="response-display p-3 bg-white shadow-sm rounded">
                        {selectedHistory ? (
                            <div>
                                <div className="response-user mb-3">
                                    <strong>You: </strong> {selectedHistory.user || "No input provided"}
                                </div>
                                <div className="response-system">
                                    <strong>Generated Scenes:</strong>
                                    <ul>
                                        {selectedHistory.response.map((scene, idx) => (
                                            <li key={idx}>{scene}</li>
                                        ))} 
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <div className="no-selection text-center text-muted">
                                Select a history item to view details.
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default SceneBreakdown;
