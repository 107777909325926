import React, { useState } from 'react';
import { Container, Row, Col, Input, Button, Card, CardBody, CardTitle, Label } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';


const ScreenplayFormatting = ({ toolsData, setToolsData }) => {
    const [screenplayInput, setScreenplayInput] = useState("");
    const [selectedHistory, setSelectedHistory] = useState(null);

    // Generate screenplay-style formatted text
    const handleGenerateScreenplay = () => {
        const formattedScreenplay = [
            "EXT. PARK - DAY",
            "A sunny day in the park. Birds are chirping.",
            "JOHN",
            "(excitedly)",
            "What a beautiful day to start something new!",
            "MARY",
            "(smiling)",
            "It’s perfect, isn’t it?",
        ];

        const newHistory = {
            user: screenplayInput.trim(),
            response: formattedScreenplay,
            timestamp: new Date().toLocaleString(),
        };

        setToolsData((prevState) => ({
            ...prevState,
            screenplayFormatting: [...prevState.screenplayFormatting, newHistory],
        }));

        setSelectedHistory(newHistory); // Auto-select the latest history
        setScreenplayInput(""); // Clear input field
    };

    // Delete a specific history item
    const handleDeleteHistory = (index) => {
        const updatedHistory = toolsData.screenplayFormatting.filter((_, i) => i !== index);

        setToolsData((prevState) => ({
            ...prevState,
            screenplayFormatting: updatedHistory,
        }));

        if (selectedHistory && toolsData.screenplayFormatting[index] === selectedHistory) {
            setSelectedHistory(null);
        }
    };

    return (
        <Container fluid className="screenplay-formatting-container">
            <Row>
                <Card className="mb-4 shadow-sm">
                    <CardBody>
                        <CardTitle tag="h4" className=" mb-4">
                            Screenplay Formatting
                        </CardTitle>
                        <Label className='label-char head-char'>Scene Description</Label>
                        <Input
                            type="text"
                            value={screenplayInput}
                            onChange={(e) => setScreenplayInput(e.target.value)}
                            placeholder="Enter story text or scene description"
                            className="mb-3"
                        />
                        <Button onClick={handleGenerateScreenplay} className='btn-gen-resp float-end'>
                            Format Screenplay
                        </Button>
                    </CardBody>
                </Card>
                {/* Sidebar */}
                <Col md="3" className="sidebar1 bg-light p-3">
                    <h5 className="text-center History mb-4">History</h5>
                    <div className="history-list">
                        {toolsData.screenplayFormatting.length > 0 ? (
                            toolsData.screenplayFormatting.map((item, index) => (
                                <div
                                    key={index}
                                    className={`history-item p-2 mb-2 d-flex align-items-center justify-content-between ${selectedHistory === item ? "selected" : ""
                                        }`}
                                    onClick={() => setSelectedHistory(item)}
                                >
                                    <div className="history-details">
                                        <div className="history-title text-truncate">
                                            {item.user || "No input provided"}
                                        </div>
                                        <div className="history-timestamp text-muted small">
                                            {item.timestamp}
                                        </div>
                                    </div>
                                    <Button
                                        size="sm"
                                        color="danger"
                                        className="delete-button"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent selecting the history item
                                            handleDeleteHistory(index);
                                        }}
                                    >
                                        <FaTrash />
                                    </Button>
                                </div>
                            ))
                        ) : (
                            <div className="no-history text-center text-muted">No history available.</div>
                        )}
                    </div>
                </Col>

                {/* Main Content */}
                <Col md="9" className="main-content p-4">


                    <div className="response-display p-3 bg-white shadow-sm rounded">
                        {selectedHistory ? (
                            <div>
                                <div className="response-user mb-3">
                                    <strong>You:</strong> {selectedHistory.user || "No input provided"}
                                </div>
                                <div className="response-system">
                                    <strong>Formatted Screenplay:</strong>
                                    <pre className="formatted-screenplay">
                                        {selectedHistory.response.map((line, idx) => (
                                            <div key={idx}>{line}</div>
                                        ))}
                                    </pre>
                                </div>
                            </div>
                        ) : (
                            <div className="no-selection text-center text-muted">
                                Select a history item to view details.
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ScreenplayFormatting;
