import React, { useState, useRef } from 'react';
import { Button, Form, FormGroup, Label, Input, Card, CardBody, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import "../../assets/style/style.css"
function Profile() {
  const [activeTab, setActiveTab] = useState('editProfile');
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
const fileInputRef = useRef(null);
  // Handle profile photo change
  const handleProfilePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePhoto(file);
      setProfilePhotoPreview(URL.createObjectURL(file)); // Set preview image
    }
  };
  const handleUpdatePhotoClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="vh-100 p-5 ">
      <Row className="" >
        <Col md="3" className="border-right">
          <div className="d-flex flex-column">
            <Button
              style={{
                backgroundColor: activeTab === 'editProfile' ? '#6A5DB4' : '#E9F3FD',
                color: activeTab === 'editProfile' ? '#fff' : '#000',
                border: 'none',
                marginBottom: '10px'
              }}
              onClick={() => setActiveTab('editProfile')}
            >
              Edit Profile
            </Button>
            <Button
              style={{
                backgroundColor: activeTab === 'changePassword' ? '#6A5DB4' : '#E9F3FD',
                color: activeTab === 'changePassword' ? '#fff' : '#000',
                border: 'none',
                marginBottom: '10px'
              }}
              onClick={() => setActiveTab('changePassword')}
            >
              Change Password
            </Button>
         
          </div>
        </Col>
        <Col md="9">
          <Card>
            <CardBody>
              {activeTab === 'editProfile' && (
                <Form>
                    <FormGroup>
                    <Label for="profilePhoto">Profile Photo</Label>
                    <div className="d-flex align-items-center">
                      <div style={{ marginRight: '15px' }}>
                        {profilePhotoPreview ? (
                          <img
                            src={profilePhotoPreview}
                            alt="Profile Preview"
                            style={{ width: '100px', height: '100px', borderRadius: '50%', objectFit: 'cover' }}
                          />
                        ) : (
                          <div className='profile-div'>
                            No Photo
                          </div>
                        )}
                      </div>

                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleProfilePhotoChange}
                      />

                      <Button
                        style={{ backgroundColor: '#6A5DB4', color: '#fff' }}
                        onClick={handleUpdatePhotoClick}
                      >
                        Update Profile Photo
                      </Button>
                    </div>
                  </FormGroup>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="firstName">First Name</Label>
                        <Input type="text" name="firstName" id="firstName" placeholder="Enter your first name" />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="lastName">Last Name *</Label>
                        <Input type="text" name="lastName" id="lastName" placeholder="Enter your last name" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input type="email" name="email" id="email" placeholder="Enter your email" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="phone">Phone Number</Label>
                    <Input type="text" name="phone" id="phone" placeholder="Enter your phone number" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="genres">Genres</Label>
                    <Input type="text" name="genres" id="genres" placeholder="Enter your genres" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="boi">Bio *</Label>
                    <Input type="textarea" name="boi" id="boi" placeholder="Enter your bio" />
                  </FormGroup>
                  <Button color="primary">Save Changes</Button>
                </Form>
              )}

              {activeTab === 'changePassword' && (
                <Form>
                  <FormGroup>
                    <Label for="currentPassword">Current Password</Label>
                    <Input type="password" name="currentPassword" id="currentPassword" placeholder="Enter your current password" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="newPassword">New Password</Label>
                    <Input type="password" name="newPassword" id="newPassword" placeholder="Enter your new password" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="confirmPassword">Confirm New Password</Label>
                    <Input type="password" name="confirmPassword" id="confirmPassword" placeholder="Confirm your new password" />
                  </FormGroup>
                  <Button color="primary">Change Password</Button>
                </Form>
              )}

              {/* Additional sections can be added here for other tabs */}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Profile;