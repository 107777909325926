import React from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';
const NDASign = ({ match }) => {
    const { id } = useParams();

  const handleSignNDA = () => {
    // NDA signing logic
  };

  return (
    <div>
      <h2>Sign NDA for Story {id}</h2>
      <p>Please review and sign the NDA agreement to proceed with mentorship.</p>
      <Button onClick={handleSignNDA}>Sign NDA</Button>
    </div>
  );
};

export default NDASign;
