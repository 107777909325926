import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Home from './pages/Home';
import About from './pages/About';
import ContactUs from './pages/ContactUs';
import ServiceOfferings from './pages/ServiceOfferings';
import TermsOfService from './pages/Legal/TermsOfService';
import PrivacyPolicy from './pages/Legal/PrivacyPolicy';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import WriterDashboard from './pages/Dashboard/WriterDashboard';
import ProducerDashboard from './pages/Dashboard/ProducerDashboard';
import MentorDashboard from './pages/Dashboard/MentorDashboard';
import UploadStory from './pages/Workflow/Writer/UploadStory';
import CreateStory from './pages/Workflow/Writer/CreateStory'
import BrowseStories from './pages/Workflow/Producer/BrowseStories';
import ReviewRequests from './pages/Workflow/Mentor/ReviewRequests';
import { useAuth } from './hooks/useAuth';
import Profile from './pages/Profile/Profile';
import Bio from './pages/Profile/Bio';
import ChangePassword from './pages/Profile/changePassword';
import ProtectedRoute from './components/ProtectingRoute';
import AIPlagiarismCheck from './pages/Workflow/Writer/AIPlagiarismCheck';
import RequestMentorship from './pages/Workflow/Writer/RequestMentorship';
import ViewFeedback from './pages/Workflow/Writer/ViewFeedback';
import NDASign from './pages/Workflow/Writer/NDASign';
import EditStoryScreen from './pages/Workflow/Writer/EditStory';
import ReadStory from './pages/Workflow/Writer/ReadStory';
import CharacterDev from './pages/Workflow/Writer/AiTools/characterDev';
import AnonymizedSummary from './pages/Workflow/Writer/AiTools/AnonymizedSum';
import SceneBreakdown from './pages/Workflow/Writer/AiTools/SceneBreakdown';
import ScreenplayFormatting from './pages/Workflow/Writer/AiTools/ScreenplayFormat';
import AudiencePotentialAnalysis from './pages/Workflow/Writer/AiTools/AudiencePotential';
import AIStoryStructureAnalysis from './pages/Workflow/Writer/AiTools/StoryStructureAnalysis';
// const ProtectedRoute = ({ element, allowedRoles }) => {
//   const { user } = useAuth();
//   return user && allowedRoles.includes(user.role) ? element : <Navigate to="/login" />;
// };

const AppRouter = () => (
  <Routes>
    {/* Public Routes */}
    <Route path="/" element={<Home />} />
    <Route path="/about" element={<About />} />
    <Route path="/contact" element={<ContactUs />} />
    <Route path="/service-offerings" element={<ServiceOfferings />} />
    <Route path="/terms-of-service" element={<TermsOfService />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    <Route path="/login" element={<Login />} />
    <Route path="/register" element={<Register />} />

    {/* Protected Routes for Authenticated Users */}
    <Route
      path="/writer/dashboard"
      element={<ProtectedRoute element={<WriterDashboard />} allowedRoles={['writer']} />}
    />
    <Route
      path="/writer/upload-story"
      element={<ProtectedRoute element={<UploadStory />} allowedRoles={['writer']} />}
    />
    <Route
      path="/writer/create-story"
      element={<ProtectedRoute element={<CreateStory />} allowedRoles={['writer']} />}
    />
    <Route
      path="/story/:id/plagiarism-check"
      element={<ProtectedRoute element={<AIPlagiarismCheck />} allowedRoles={['writer']} />}
    />
    <Route
      path="/story/:id/find-mentor"
      element={<ProtectedRoute element={<RequestMentorship />} allowedRoles={['writer']} />}
    />
    <Route
      path="/story/:id/feedback"
      element={<ProtectedRoute element={<ViewFeedback />} allowedRoles={['writer']} />}
    />
    <Route
      path="/story/:id/nda"
      element={<ProtectedRoute element={<NDASign />} allowedRoles={['writer']} />}
    />
    <Route
      path="/story/:id/editStory"
      element={<ProtectedRoute element={<EditStoryScreen />} allowedRoles={['writer']} />}
    />

    <Route
      path="/story/:id/readStory"
      element={<ProtectedRoute element={<ReadStory />} allowedRoles={['writer']} />}
    />
    <Route
      path="/edit/:id/characterdev"
      element={<ProtectedRoute element={<CharacterDev />} allowedRoles={['writer']} />}
    />
    <Route
      path="/edit/:id/anonymized"
      element={<ProtectedRoute element={<AnonymizedSummary />} allowedRoles={['writer']} />}
    />
    <Route
      path="/edit/:id/SceneBreakdown"
      element={<ProtectedRoute element={<SceneBreakdown />} allowedRoles={['writer']} />}
    />
    <Route
      path="/edit/:id/SceneBreakdown"
      element={<ProtectedRoute element={<ScreenplayFormatting />} allowedRoles={['writer']} />}
    />
     <Route
      path="/edit/:id/SceneBreakdown"
      element={<ProtectedRoute element={<AudiencePotentialAnalysis />} allowedRoles={['writer']} />}
    />
     <Route
      path="/edit/:id/SceneBreakdown"
      element={<ProtectedRoute element={<AIStoryStructureAnalysis />} allowedRoles={['writer']} />}
    />
    {/* --------------------------------------------------------------------------------------------------------------------- */}
    <Route
      path="/producer/dashboard"
      element={<ProtectedRoute element={<ProducerDashboard />} allowedRoles={['producer']} />}
    />
    <Route
      path="/producer/browse-stories"
      element={<ProtectedRoute element={<BrowseStories />} allowedRoles={['producer']} />}
    />

    <Route
      path="/mentor/dashboard"
      element={<ProtectedRoute element={<MentorDashboard />} allowedRoles={['mentor']} />}
    />
    <Route
      path="/mentor/review-requests"
      element={<ProtectedRoute element={<ReviewRequests />} allowedRoles={['mentor']} />}
    />

    {/* Profile Routes (Accessible to All Authenticated Roles) */}
    <Route
      path="/profile"
      element={<ProtectedRoute element={<Profile />} allowedRoles={['writer', 'producer', 'mentor']} />}
    />
    <Route
      path="/profile/bio"
      element={<ProtectedRoute element={<Bio />} allowedRoles={['writer', 'producer', 'mentor']} />}
    />
    <Route
      path="/profile/change-password"
      element={<ProtectedRoute element={<ChangePassword />} allowedRoles={['writer', 'producer', 'mentor']} />}
    />

    {/* Catch-All Route for Unmatched Paths */}
    <Route path="*" element={<Navigate to="/" />} />

  </Routes>
);

export default AppRouter;