const registerIcon = require("../assets/images/Vector 93.png")
const storylogo = require("../assets/images/storylogo.png")
const book = require("../assets/images/bookimg.png")
const rgwriter = require("../assets/images/regwriter.png")
const rgproducer = require("../assets/images/rgproducer.png")
const rgmentor = require("../assets/images/rgmentor.png")
const homeimg = require("../assets/images/homeImg.png")
const managementIcon = require("../assets/images/management-icon.png")
const mentorIcon = require("../assets/images/mentorIcon.png")
const contractIcon = require("../assets/images/contractIcon.png")
const notiIcon = require("../assets/images/notiIcon.png")
const keyImg = require("../assets/images/keyfeatureimg.png")
const aboutimg =require("../assets/images/aboutimg.png")
const vision = require("../assets/images/vision.png")
const writerIcon = require("../assets/images/writerIcon.png")
const producericon = require("../assets/images/producerIcon.png")
const mentor =require("../assets/images/mentorAbout.png")
export {
    registerIcon,
    storylogo,
    book,
    rgwriter,
    rgproducer,
    rgmentor,
    homeimg,
    managementIcon,
    notiIcon,contractIcon,mentorIcon, keyImg,aboutimg, vision,
    mentor,producericon,writerIcon
}