import React from 'react';

const Bio = () => {
  return (
    <div>
      <h1>Bio</h1>
      <p>Edit your bio here.</p>
    </div>
  );
};

export default Bio;
