import React, { useState } from 'react';
import { Container, Row, Col, Input, Button, Card, CardBody, CardTitle, Label } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';

const CharacterDev = ({ toolsData, setToolsData }) => {
    const [characterInput, setCharacterInput] = useState("");
    const [selectedHistory, setSelectedHistory] = useState(null);

    // Handle the prompt generation for Character Development tool
    const handleGeneratePrompt = () => {
        const prompt = characterInput.trim()
            ? `Character Development prompt generated at ${new Date().toLocaleTimeString()} with input: ${characterInput}`
            : `Character Development prompt generated at ${new Date().toLocaleTimeString()}`;

        const newHistory = {
            user: characterInput.trim(),
            response: prompt,
            timestamp: new Date().toLocaleString(),
        };

        // Update tool data state
        setToolsData((prevState) => ({
            ...prevState,
            characterDevelopment: [...prevState.characterDevelopment, newHistory],
        }));

        setSelectedHistory(newHistory); // Auto-select the latest history
        setCharacterInput(""); // Clear input field
    };
    const handleDeleteHistory = (index) => {
        const updatedHistory = toolsData.characterDevelopment.filter((_, i) => i !== index);

        setToolsData((prevState) => ({
            ...prevState,
            characterDevelopment: updatedHistory,
        }));

        // Clear selected history if it was deleted
        if (selectedHistory && toolsData.characterDevelopment[index] === selectedHistory) {
            setSelectedHistory(null);
        }
    };
    return (
        <Container fluid className="character-dev-container">
            <Row>
                <Card className="mb-4 shadow-sm">
                    <CardBody>
                        <CardTitle tag="h4" className=" mb-4 head-char">
                            Character Development Tool
                        </CardTitle>
                        <Label className='label-char'>Character Name</Label>
                        <Input
                            type="text"
                            value={characterInput}
                            onChange={(e) => setCharacterInput(e.target.value)}
                            placeholder="Enter character details or attributes"
                            className="mb-3"
                        />
                        <Label className='label-char'>Traits</Label>
                        <Input
                            type="text"
                            placeholder="List charcter traits"
                            className="mb-3"
                        />
                        <Button onClick={handleGeneratePrompt} className='btn-gen-resp float-end' >
                            Generate Response
                        </Button>
                    </CardBody>
                </Card>
                <Col md="3" className="sidebar1 bg-light p-3 ">
                    <h5 className="History text-center mb-4">History</h5>
                    <div className="history-list">
                        {toolsData.characterDevelopment.length > 0 ? (
                            toolsData.characterDevelopment.map((item, index) => (
                                <div
                                    key={index}
                                    className={`history-item p-2 mb-2 d-flex align-items-center justify-content-between ${selectedHistory === item ? "selected" : ""
                                        }`}
                                    onClick={() => setSelectedHistory(item)}
                                >
                                    <div className="history-details">
                                        <div className="history-title text-truncate">
                                            {item.user || "No input provided"}
                                        </div>
                                        <div className="history-timestamp text-muted small">
                                            {item.timestamp}
                                        </div>
                                    </div>
                                    <Button
                                        size="sm"
                                        color="danger"
                                        className="delete-button"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent selecting the history item
                                            handleDeleteHistory(index);
                                        }}
                                    >
                                        <FaTrash size={12} />
                                    </Button>
                                </div>
                            ))
                        ) : (
                            <div className="no-history text-center text-muted">No history available.</div>
                        )}
                    </div>

                </Col>
                <Col md="9" className="main-con p-4">


                    <div className="response-display p-4 bg-light shadow-lg rounded">
                        <h3 className="response-title mb-4">Response</h3>
                        {selectedHistory ? (
                            <div>
                                <div className="response-user mb-3">
                                    <strong className="response-label">Your prompt: </strong>
                                    <span className="response-content">{selectedHistory.user || "No input provided"}</span>
                                </div>
                                <div className="response-system">
                                    <strong className="response-label">Response:</strong>
                                    <p className="response-content">{selectedHistory.response}</p>
                                </div>
                            </div>
                        ) : (
                            <div className="no-selection text-center text-muted">
                                Select a history item to view details.
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default CharacterDev;
